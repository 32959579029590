import { LandscapeIcon } from "components/common/Icons/LandscapeIcon";
import { Modal } from "components/common/Modal/Modal";
import { UploaderParent } from "../UploaderParent";
import { UploadTypeSelector } from "../UploadTypeSelector/UploadTypeSelector";
import { UrlUpload } from "./UrlUploader/UrlUpload";
import { type ImageUploaderOptions, useImageUploader } from "./useImageUploader";
import { UploadProgressList } from "../UploadProgressList/UploadProgressList";
import { DeleteImage } from "components/modules/Uploader/useUploader";
import { Translate } from "components/common/Translate/Translate";
import { type ReactNode } from "react";
import { InvalidImageExtensionModal } from "components/modules/Uploader/InvalidImageExtensionModal/InvalidImageExtensionModal";

export interface ImageUploaderProps {
    maxUploads: number;
    deleteImage: DeleteImage;
    deleteAllImages: () => void;
    htmlFileInputId: string;
    analytics: {
        pageKey: string;
        pageTitle: string;
    };
    supportedExtensions: string[];
    settingsSlot?: ReactNode;
    shouldSkipImport?: boolean;
    skipImportAfterTaskCreate?: boolean;
    filterFiles?: ImageUploaderOptions["filterFiles"];
}

export const UPLOADER_CONTAINER_ID = `image-uploader-container`;

export const ImageUploader = ({
    maxUploads,
    deleteImage,
    deleteAllImages,
    htmlFileInputId,
    analytics,
    supportedExtensions,
    settingsSlot,
    shouldSkipImport,
    skipImportAfterTaskCreate,
    filterFiles,
}: ImageUploaderProps) => {
    const { uploadType, handleFile, handleImportCancel, handleCancelAllImports, isDragging } = useImageUploader(
        { maxUploads, shouldSkipImport, skipImportAfterTaskCreate, supportedExtensions, filterFiles },
        deleteAllImages,
    );

    const allowMultiple = maxUploads > 1;
    return (
        <div className="flex flex-col gap-4">
            <UploaderParent>
                <div
                    className="w-full h-full py-10 px-4 bg-irBlue-200 dark:bg-darkPrimary-100 flex items-center justify-center border-4 border-irBlue-600 dark:border-darkPrimary-400 border-dashed"
                    id={UPLOADER_CONTAINER_ID}
                >
                    {uploadType !== "url" ? (
                        <div className="flex flex-col items-center text-sm md:text-base">
                            <LandscapeIcon className="fill-white" />
                            <UploadTypeSelector
                                handleFile={handleFile}
                                allowMultiple={allowMultiple}
                                htmlFileInputId={htmlFileInputId}
                                analytics={analytics}
                                supportedExtensions={supportedExtensions}
                            />
                            <div className="text-black mt-4 font-semibold">
                                {allowMultiple ? (
                                    <Translate keyName="uploader-sub-title-plural" />
                                ) : (
                                    <Translate keyName="uploader-sub-title" />
                                )}
                            </div>
                        </div>
                    ) : (
                        <UrlUpload handleFile={handleFile} supportedExtensions={supportedExtensions} />
                    )}
                </div>
                <Modal isOpen={isDragging}>
                    <div className="w-full h-screen bg-blue-400 flex items-center justify-center text-white font-semibold text-2xl">
                        <Translate keyName="uploader-dragging-title" />
                    </div>
                </Modal>
            </UploaderParent>
            {settingsSlot}
            <UploadProgressList
                deleteImage={deleteImage}
                handleImportCancel={handleImportCancel}
                handleCancelAllImports={handleCancelAllImports}
                skipDeviceImport={skipImportAfterTaskCreate}
            />
            <InvalidImageExtensionModal />
        </div>
    );
};
